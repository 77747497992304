import { Button } from "react-bootstrap";
import styled from "styled-components";
import {
  Button as MuiButton,
  makeStyles,
  createStyles,
} from "@material-ui/core";
import { darken } from "@material-ui/core/styles/colorManipulator";
import clsx from "clsx";

export const ButtonPrimary = styled(Button)`
  background: ${(props) => props.theme.colors.primary} !important;
  border-color: ${(props) => props.theme.colors.primary} !important;
  border-radius: 0;
  padding: 12px 30px;
  box-shadow: none !important;
  font-size: 1.5rem;
`;
export const LoginScreenButtonPrimary = styled(Button)`
  background: ${(props) => props.theme.colors.primary} !important;
  border-color: ${(props) => props.theme.colors.primary} !important;
  border-radius: 20px;
  padding: 12px 30px;
  box-shadow: none !important;
  font-size: 1.2rem;
`;
export const ButtonSecondary = styled(Button)`
  background: white !important;
  color: ${(props) => props.theme.colors.primary} !important;
  border-color: ${(props) => props.theme.colors.primary} !important;
  border-radius: 0;
  padding: 12px 30px;
  box-shadow: none !important;
  font-size: 1.5rem;
`;

export const LoginScreenButtonSecondary = styled(Button)`
background:transparent!important;
  color: ${(props) => props.theme.colors.primary} !important;
  border-color: transparent !important;
  text-decoration:underline;
  border-radius: 20px;
  padding: 12px 30px;
  box-shadow: none !important;
  font-size: 1.2rem!important;
  font-weight:600;
`;

const getBackgroundColor = (theme, color, variant) => {
  if (["outlined", "text"].includes(variant)) {
    return theme.palette.initial.main;
  }

  switch (color) {
    case "primary":
      return theme.palette.primary.main;
    case "success":
      return theme.palette.success.main;
    case "error":
      return theme.palette.error.main;
    case "default":
      return theme.palette.default.main;
    case "initial":
      return theme.palette.initial.main;
    case "error-outline":
      return theme.palette.initial.main;
    case "info":
      return theme.palette.blue.main;
    case "info-outline":
      return theme.palette.initial.main;
    case "yellow":
      return theme.palette.yellow.main;
    default:
      return color;
  }
};

const getBorderColor = (theme, color) => {
  let border = "none";
  switch (color) {
    case "default":
      border = `1px solid ${theme.palette.grey.main}`;
      break;
    case "initial":
      border = `1px solid ${theme.palette.primary.main}`;
      break;
    case "error-outline":
      border = `1px solid ${theme.palette.error.main}`;
      break;

    default:
      return "";
  }
  return border;
};

const getTextColor = (theme, color) => {
  let textColor = "white";
  switch (color) {
    case "green":
      textColor = "#69CDAE";
      break;
    case "default":
      textColor = "black";
      break;
    case "initial":
      textColor = theme.palette.primary.main;
      break;
    case "error-outline":
      textColor = theme.palette.error.main;
      break;
    case "info-outline":
      textColor = theme.palette.blue.main;
      break;
    default:
      return "white";
  }
  return textColor;
};

const makeButtonStyles = ({
  buttonColor = "#fff",
  passedTextColor,
  radius = 0,
  noMargin,
  variant,
  fontSize,
  fontWeight,
  disablePadding,
}) => {
  const styles = makeStyles((theme) => {
    const bgColor = getBackgroundColor(theme, buttonColor, variant);
    const borderColor = getBorderColor(theme, buttonColor);
    const textColor = passedTextColor || getTextColor(theme, buttonColor);
    return createStyles({
      btnColor: {
        backgroundColor: bgColor,
        border: borderColor,
        color: `${textColor} !important`,
        // minHeight: "38px",
        minWidth: "auto",
        "&:hover": {
          backgroundColor: darken(bgColor, 0.1),
          borderColor: darken(bgColor, 0.2),
          "&.Mui-disabled": {
            backgroundColor: bgColor,
          },
          color: buttonColor === "default" ? "black" : "white",
        },
        "&.Mui-disabled": {
          backgroundColor: bgColor,
          opacity: 0.5,
        },
      },
      root: {
        borderRadius: radius,
        height: "fit-content",
        textTransform: "capitalize",
        padding: disablePadding ? 0 : "8px 16px",
        color: "white",
        fontSize: fontSize || 12,
        fontWeight: fontWeight || 400,
        margin: noMargin ? 0 : 8,
      },
    });
  });

  return styles();
};

function CommonButton(props) {
  const {
    color = "primary",
    className,
    children,
    disabled,
    textColor,
    radius,
    noMargin = false,
    disablePadding = false,
    variant,
    fontSize,
    fontWeight,
    ...other
  } = props;

  const classes = makeButtonStyles({
    buttonColor: color,
    passedTextColor: textColor,
    radius,
    noMargin,
    variant,
    fontSize,
    fontWeight,
    disablePadding,
  });

  return (
    <MuiButton
      className={clsx(classes.btnColor, classes.root, className)}
      {...other}
      disabled={!!disabled}
    >
      {children}
    </MuiButton>
  );
}

export default CommonButton;
